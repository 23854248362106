@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .complete-heart {
    box-shadow: 20px 10px 0 0 rgba(255, 0, 0, 1), 30px 10px 0 0 rgba(255, 0, 0, 1), 50px 10px 0 0 rgba(255, 0, 0, 1), 60px 10px 0 0 rgba(255, 0, 0, 1), 10px 20px 0 0 rgba(255, 0, 0, 1), 20px 20px 0 0 rgba(255, 0, 0, 1), 30px 20px 0 0 rgba(255, 0, 0, 1), 40px 20px 0 0 rgba(255, 0, 0, 1), 50px 20px 0 0 rgba(255, 0, 0, 1), 60px 20px 0 0 rgba(255, 0, 0, 1), 70px 20px 0 0 rgba(255, 0, 0, 1), 20px 30px 0 0 rgba(255, 0, 0, 1), 30px 30px 0 0 rgba(255, 0, 0, 1), 40px 30px 0 0 rgba(255, 0, 0, 1), 50px 30px 0 0 rgba(255, 0, 0, 1), 60px 30px 0 0 rgba(255, 0, 0, 1), 30px 40px 0 0 rgba(255, 0, 0, 1), 40px 40px 0 0 rgba(255, 0, 0, 1), 50px 40px 0 0 rgba(255, 0, 0, 1), 40px 50px 0 0 rgba(255, 0, 0, 1);
    height: 10px;
    width: 10px;
  }

  .half-heart {
    box-shadow: 20px 10px 0 0 rgba(255, 0, 0, 1), 30px 10px 0 0 rgba(255, 0, 0, 1), 10px 20px 0 0 rgba(255, 0, 0, 1), 20px 20px 0 0 rgba(255, 0, 0, 1), 30px 20px 0 0 rgba(255, 0, 0, 1), 40px 20px 0 0 rgba(255, 0, 0, 1), 20px 30px 0 0 rgba(255, 0, 0, 1), 30px 30px 0 0 rgba(255, 0, 0, 1), 30px 40px 0 0 rgba(255, 0, 0, 1), 40px 40px 0 0 rgba(255, 0, 0, 1), 40px 50px 0 0 rgba(255, 0, 0, 1);
    height: 10px;
    width: 10px;
  }
}
