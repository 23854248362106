.durer-imgs {
  display: flex
}

@keyframes slidein { 
  0% { transform: rotate(90deg); width: 300px; top: 50%; left: 45% } 
 25% { transform: rotate(0deg); width: 75px; top: 70%; left: 30%}
 50% { transform: rotate(45deg); width: 500px; top: 34%; left: 80%}
 75% { transform: rotate(180deg); width: 1000px; top: 5%; left: 10%}    
 100% { transform: rotate(90deg); width: 300px; top: 50%; left: 45%}    
}