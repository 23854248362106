button:focus {
  outline: none;
}

.windows {
  background-color: #D6D3CE;
  border: 3px solid;
  border-top-color: #FFFCF1;
  border-left-color: #FFFCF1;
  border-bottom-color: #6F6C6F ;
  border-right-color: #6F6C6F;
  font-family: "MS Sans-serif", sans-serif;
}


.win-header {
  background-color: $blue-95;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.win-title {
  text-align: left;
  margin: 0px;
  padding: 0px;
  color: white;
}


.win-close {
  position: relative;
  background-color: #D6D3CE;
  color: black;
  width: 24px;
    height: 24px;
  border: 2px solid;
  border-top-color: $windows-light;
  border-left-color: $windows-light;
  border-bottom-color: $windows-shadow;
  border-right-color: $windows-shadow;
  font-size: 22px;
  font-family: inherit;
  font-weight: bold;

  .win-x {
    position: absolute;
    bottom: -4px;
      left: 4px;
  }
}

.win-button {
  min-width: 75px;
  min-height: 20px;
  border-width: 1px 3px 3px 1px;
  border-style: solid;
  border-color: black;
  text-decoration: none;
  padding: 2px;
}

.win-text-button {
  border-style: dotted;
  color: black;
  border-width: 1px
}

.generic-container {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100vw;
}
/*absolute box-border top-0 left-0 flex w-screen h-full flex-col */

.generic-content {
  flex-grow: 100;
  display: flex;
  flex-direction: column;
}

.generic-inner-content {
  overflow: auto;
  border: 2px solid;
  border-top-color: $windows-shadow;
  border-right-color: $windows-light;
  border-bottom-color: $windows-light;
  border-left-color: $windows-shadow;
  flex-grow: 100;
  margin: 3px;

  ul {
    padding: 0;
    margin: 0;
  }
}

.win-menu {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 30px;
  background-color: $windows-grey;
  border-top: 3px solid;
  border-top-color: $windows-light;

  .start {
    background-color: $windows-grey;
    border: 2px solid;
    display: flex;
    font-size: 20px;
    // font-family: $win-font;
  }

  .win-start {
    border-top-color: $windows-light;
    border-left-color: $windows-light;
    border-bottom-color: $windows-shadow;
    border-right-color: $windows-shadow;
  }

  .win-active {
    border-top-color: $windows-shadow;
    border-right-color: $windows-light;
    border-bottom-color: $windows-light;
    border-left-color: $windows-shadow;
  }
}

.open-menu {
  display: flex;
  border: 2px solid;
  border-top-color: $windows-light;
  border-left-color: $windows-light;
  border-bottom-color: $windows-shadow;
  border-right-color: $windows-shadow;
  background-color: $windows-grey;
  background-color: $windows-grey ;
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 200px;
  height:  300px;

  .side-title {
    background-color: $windows-shadow;
    width: 40px;
    color: white;
    font-weight: bold;
    border-bottom: solid 2px $windows-grey;
    p {
      position: absolute;
      transform: rotate(-90deg);
      bottom: 50px;
      left: -22px;
    }
  }

  .app-list {
    flex-grow: 100;

    .app-item {
      height: 48px;
      border: solid $windows-shadow;
      border-width: 0 0 2px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      
      button {
        padding: 0;
        height: 48px;
        flex-grow: 100;
        font-size: 16px;
        border: none;
        background-color: transparent;
      }
    }
  }
}

.gal-img {
  max-height: 80vh;
  max-width: 85vw;

}