.ninetyFive {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  overflow: hidden;
  text-align: center;
  background-color: $death-blue;
  // height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}

.global-context {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.global-content {
  width: 100%;
  text-align: left;
  position: relative;
  padding: 20px;
  flex-grow: 100;
}

.bottom {
  
}